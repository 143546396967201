import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HeroLogo = () => {
  return (
    <>
      <StaticImage
        placeholder="dominantColor"
        className="img-fluid rounded mx-2"
        imgClassName="w-100"
        src="../../images/pipefy-logo-new-400.jpg"
        alt="Pipefy Logo"
        layout="constrained"
        height={70}
        loading="lazy"
      />

      <StaticImage
        placeholder="dominantColor"
        className="img-fluid rounded mx-2"
        src="../../images/new-images/gold-blk-400.jpg"
        alt="Workato Badge"
        layout="constrained"
        loading="lazy"
        height={70}
      />

      <StaticImage
        placeholder="dominantColor"
        className="img-fluid rounded mx-2"
        imgClassName="w-100"
        src="../../images/new-images/qb-logo-2022-alt-400.png"
        alt="Solution Provider"
        layout="constrained"
        height={70}
        loading="lazy"
      />
    </>
  )
}

export default HeroLogo
